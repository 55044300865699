import * as React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SeoBasic from "../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import ReactHtmlParser from "react-html-parser"

export const query = graphql`
  query {
    wpPage(slug: {eq: "privacy-policy"}) {
    title
    content
  }
  seoPage:wpPage(slug: {eq: "privacy-policy"}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
  }
`

const IndexPage = ({
  data: {
    wpPage: { title, content }, seoPage
  },
}) => {

  return(
  <Layout>
    {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title={title} />
    }
    <div className="simpleText" style={{backgroundColor:"#2F2F2F"}}>
      
      <h1>{title}</h1>
      {ReactHtmlParser(content)}

    </div>

   
  </Layout>
)
}

export default IndexPage
